<template>
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.32324 0.863281C1.66639 0.863281 0.323242 2.20643 0.323242 3.86328V13.136C0.323242 14.7928 1.66639 16.136 3.32324 16.136H14.1232C15.7801 16.136 17.1232 14.7928 17.1232 13.136V3.86328C17.1232 2.20643 15.7801 0.863281 14.1232 0.863281H3.32324ZM9.34893 2.39082C8.24436 2.39082 7.34893 3.28625 7.34893 4.39082V12.609C7.34893 13.7136 8.24436 14.609 9.34893 14.609H13.4435C14.548 14.609 15.4435 13.7136 15.4435 12.609V4.39082C15.4435 3.28625 14.548 2.39082 13.4435 2.39082H9.34893Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
