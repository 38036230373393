import { default as attributespwHbf7yhZhMeta } from "/var/www/apps/admin.korner.ng/pages/attributes.vue?macro=true";
import { default as forgot_45passwordWqCXUEqoiNMeta } from "/var/www/apps/admin.korner.ng/pages/auth/forgot-password.vue?macro=true";
import { default as login00bgOnQYywMeta } from "/var/www/apps/admin.korner.ng/pages/auth/login.vue?macro=true";
import { default as verify_45accounthMzWopOC6iMeta } from "/var/www/apps/admin.korner.ng/pages/auth/verify-account.vue?macro=true";
import { default as brands2sNDrAJulDMeta } from "/var/www/apps/admin.korner.ng/pages/brands.vue?macro=true";
import { default as _91slug_93IWBrmkK8B6Meta } from "/var/www/apps/admin.korner.ng/pages/categories/[slug].vue?macro=true";
import { default as indexQY8tw5WsWRMeta } from "/var/www/apps/admin.korner.ng/pages/categories/index.vue?macro=true";
import { default as categoriesMSLkngo1xTMeta } from "/var/www/apps/admin.korner.ng/pages/categories.vue?macro=true";
import { default as _91id_93AtscgCCb0KMeta } from "/var/www/apps/admin.korner.ng/pages/customers/[id].vue?macro=true";
import { default as index5eVxjPY6cHMeta } from "/var/www/apps/admin.korner.ng/pages/customers/index.vue?macro=true";
import { default as indexJBU9WV91hgMeta } from "/var/www/apps/admin.korner.ng/pages/index.vue?macro=true";
import { default as _91id_93jl7wmmlcYPMeta } from "/var/www/apps/admin.korner.ng/pages/orders/[id].vue?macro=true";
import { default as completed_45refundY4KODYQX9wMeta } from "/var/www/apps/admin.korner.ng/pages/orders/completed-refund.vue?macro=true";
import { default as indexhfaZH198LYMeta } from "/var/www/apps/admin.korner.ng/pages/orders/index.vue?macro=true";
import { default as pending_45refundQ4XvCKJunFMeta } from "/var/www/apps/admin.korner.ng/pages/orders/pending-refund.vue?macro=true";
import { default as view9DRyd9iMvaMeta } from "/var/www/apps/admin.korner.ng/pages/orders/view.vue?macro=true";
import { default as ordersYT5JIt3KeNMeta } from "/var/www/apps/admin.korner.ng/pages/orders.vue?macro=true";
import { default as bulk_45restock_45requestslD8x00SDGOMeta } from "/var/www/apps/admin.korner.ng/pages/products/bulk-restock-requests.vue?macro=true";
import { default as create_45newZlEiwPMYEWMeta } from "/var/www/apps/admin.korner.ng/pages/products/create-new.vue?macro=true";
import { default as _91slug_93eAqyAZv4SMMeta } from "/var/www/apps/admin.korner.ng/pages/products/edit/[slug].vue?macro=true";
import { default as indexcQgOx84Cb5Meta } from "/var/www/apps/admin.korner.ng/pages/products/edit/index.vue?macro=true";
import { default as indexFfcDc2sa0aMeta } from "/var/www/apps/admin.korner.ng/pages/products/index.vue?macro=true";
import { default as low_45restock_45requests0Ao6Yu796jMeta } from "/var/www/apps/admin.korner.ng/pages/products/low-restock-requests.vue?macro=true";
import { default as new_45requestzXyz4kV6IZMeta } from "/var/www/apps/admin.korner.ng/pages/products/new-request.vue?macro=true";
import { default as restock_45requestsarTmd5vDBrMeta } from "/var/www/apps/admin.korner.ng/pages/products/restock-requests.vue?macro=true";
import { default as viewcYdveLxKMiMeta } from "/var/www/apps/admin.korner.ng/pages/products/view.vue?macro=true";
import { default as productsSU7ECZBL3mMeta } from "/var/www/apps/admin.korner.ng/pages/products.vue?macro=true";
import { default as profilefAvqyxixFDMeta } from "/var/www/apps/admin.korner.ng/pages/profile.vue?macro=true";
import { default as index58ZxRIM1gpMeta } from "/var/www/apps/admin.korner.ng/pages/settings/index.vue?macro=true";
import { default as _91slug_931et8SJ8RzcMeta } from "/var/www/apps/admin.korner.ng/pages/site-settings/[slug].vue?macro=true";
import { default as index67Ig5m4ckfMeta } from "/var/www/apps/admin.korner.ng/pages/site-settings/index.vue?macro=true";
import { default as _91slug_93SCNWTiaiMTMeta } from "/var/www/apps/admin.korner.ng/pages/suppliers/[slug].vue?macro=true";
import { default as indexc7KINwazI7Meta } from "/var/www/apps/admin.korner.ng/pages/suppliers/index.vue?macro=true";
import { default as suppliersaGNY4xFty5Meta } from "/var/www/apps/admin.korner.ng/pages/suppliers.vue?macro=true";
import { default as indexZpZ71Kia5lMeta } from "/var/www/apps/admin.korner.ng/pages/transactions/index.vue?macro=true";
export default [
  {
    name: "attributes",
    path: "/attributes",
    component: () => import("/var/www/apps/admin.korner.ng/pages/attributes.vue").then(m => m.default || m)
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordWqCXUEqoiNMeta || {},
    component: () => import("/var/www/apps/admin.korner.ng/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: login00bgOnQYywMeta || {},
    component: () => import("/var/www/apps/admin.korner.ng/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-verify-account",
    path: "/auth/verify-account",
    meta: verify_45accounthMzWopOC6iMeta || {},
    component: () => import("/var/www/apps/admin.korner.ng/pages/auth/verify-account.vue").then(m => m.default || m)
  },
  {
    name: "brands",
    path: "/brands",
    component: () => import("/var/www/apps/admin.korner.ng/pages/brands.vue").then(m => m.default || m)
  },
  {
    name: categoriesMSLkngo1xTMeta?.name,
    path: "/categories",
    component: () => import("/var/www/apps/admin.korner.ng/pages/categories.vue").then(m => m.default || m),
    children: [
  {
    name: "categories-slug",
    path: ":slug()",
    component: () => import("/var/www/apps/admin.korner.ng/pages/categories/[slug].vue").then(m => m.default || m)
  },
  {
    name: "categories",
    path: "",
    component: () => import("/var/www/apps/admin.korner.ng/pages/categories/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "customers-id",
    path: "/customers/:id()",
    component: () => import("/var/www/apps/admin.korner.ng/pages/customers/[id].vue").then(m => m.default || m)
  },
  {
    name: "customers",
    path: "/customers",
    component: () => import("/var/www/apps/admin.korner.ng/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/apps/admin.korner.ng/pages/index.vue").then(m => m.default || m)
  },
  {
    name: ordersYT5JIt3KeNMeta?.name,
    path: "/orders",
    component: () => import("/var/www/apps/admin.korner.ng/pages/orders.vue").then(m => m.default || m),
    children: [
  {
    name: "orders-id",
    path: ":id()",
    component: () => import("/var/www/apps/admin.korner.ng/pages/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "orders-completed-refund",
    path: "completed-refund",
    component: () => import("/var/www/apps/admin.korner.ng/pages/orders/completed-refund.vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "",
    component: () => import("/var/www/apps/admin.korner.ng/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-pending-refund",
    path: "pending-refund",
    component: () => import("/var/www/apps/admin.korner.ng/pages/orders/pending-refund.vue").then(m => m.default || m)
  },
  {
    name: "orders-view",
    path: "view",
    component: () => import("/var/www/apps/admin.korner.ng/pages/orders/view.vue").then(m => m.default || m)
  }
]
  },
  {
    name: productsSU7ECZBL3mMeta?.name,
    path: "/products",
    component: () => import("/var/www/apps/admin.korner.ng/pages/products.vue").then(m => m.default || m),
    children: [
  {
    name: "products-bulk-restock-requests",
    path: "bulk-restock-requests",
    component: () => import("/var/www/apps/admin.korner.ng/pages/products/bulk-restock-requests.vue").then(m => m.default || m)
  },
  {
    name: "products-create-new",
    path: "create-new",
    component: () => import("/var/www/apps/admin.korner.ng/pages/products/create-new.vue").then(m => m.default || m)
  },
  {
    name: "products-edit-slug",
    path: "edit/:slug()",
    component: () => import("/var/www/apps/admin.korner.ng/pages/products/edit/[slug].vue").then(m => m.default || m)
  },
  {
    name: "products-edit",
    path: "edit",
    component: () => import("/var/www/apps/admin.korner.ng/pages/products/edit/index.vue").then(m => m.default || m)
  },
  {
    name: "products",
    path: "",
    component: () => import("/var/www/apps/admin.korner.ng/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: "products-low-restock-requests",
    path: "low-restock-requests",
    component: () => import("/var/www/apps/admin.korner.ng/pages/products/low-restock-requests.vue").then(m => m.default || m)
  },
  {
    name: "products-new-request",
    path: "new-request",
    component: () => import("/var/www/apps/admin.korner.ng/pages/products/new-request.vue").then(m => m.default || m)
  },
  {
    name: "products-restock-requests",
    path: "restock-requests",
    component: () => import("/var/www/apps/admin.korner.ng/pages/products/restock-requests.vue").then(m => m.default || m)
  },
  {
    name: "products-view",
    path: "view",
    component: () => import("/var/www/apps/admin.korner.ng/pages/products/view.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/var/www/apps/admin.korner.ng/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/var/www/apps/admin.korner.ng/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "site-settings-slug",
    path: "/site-settings/:slug()",
    component: () => import("/var/www/apps/admin.korner.ng/pages/site-settings/[slug].vue").then(m => m.default || m)
  },
  {
    name: "site-settings",
    path: "/site-settings",
    component: () => import("/var/www/apps/admin.korner.ng/pages/site-settings/index.vue").then(m => m.default || m)
  },
  {
    name: suppliersaGNY4xFty5Meta?.name,
    path: "/suppliers",
    component: () => import("/var/www/apps/admin.korner.ng/pages/suppliers.vue").then(m => m.default || m),
    children: [
  {
    name: "suppliers-slug",
    path: ":slug()",
    component: () => import("/var/www/apps/admin.korner.ng/pages/suppliers/[slug].vue").then(m => m.default || m)
  },
  {
    name: "suppliers",
    path: "",
    component: () => import("/var/www/apps/admin.korner.ng/pages/suppliers/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "transactions",
    path: "/transactions",
    component: () => import("/var/www/apps/admin.korner.ng/pages/transactions/index.vue").then(m => m.default || m)
  }
]